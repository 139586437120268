<template>
    <div class="container">
        <div class="container_login">
            <div class="container_login_con">
                <img src="../assets/nav_logo@3x.png" alt="" class="container_login_con_img" />
                <div class="container_login_con_tips">
                    <div
                        class="container_login_con_tips_con"
                        :class="{ container_login_con_tips_con_active: active === 0 }"
                        @click="active = 0"
                    >
                        账号登录
                    </div>
                    <div
                        class="container_login_con_tips_con"
                        :class="{ container_login_con_tips_con_active: active === 1 }"
                        @click="active = 1"
                    >
                        免密登录
                    </div>
                </div>
                <div class="login">
                    <el-form v-if="active === 0" :model="form" ref="form" :rules="rules" label-width="0">
                        <el-form-item
                            prop="username"
                            :rules="{
                                required: true,
                                pattern: /^1[3-9]\d{9}$/,
                                message: '请输入正确的手机号',
                                trigger: 'blur'
                            }"
                        >
                            <span class="el-icon-user"></span>
                            <el-input
                                style="width: 300px"
                                v-model="form.username"
                                placeholder="请输入账号/手机号"
                            ></el-input>
                        </el-form-item>
                        <div @keyup.enter="Login">
                            <el-form-item
                                prop="password"
                                :rules="{ required: true, message: '请输入', trigger: 'blur' }"
                            >
                                <span class="el-icon-unlock"></span>
                                <el-input
                                    style="width: 300px"
                                    v-model="form.password"
                                    placeholder="请输入密码"
                                    type="password"
                                ></el-input>
                            </el-form-item>
                        </div>
                    </el-form>
                    <el-form v-else :model="form" ref="form" label-width="0">
                        <el-form-item
                            prop="phone"
                            :rules="{
                                pattern: /^1[3-9]\d{9}$/,
                                message: '请输入正确的手机号',
                                trigger: 'blur'
                            }"
                        >
                            <span class="el-icon-user"></span>
                            <el-select style="width: 100px" v-model="form.region" placeholder="请选择">
                                <el-option v-for="item in list" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                            <el-input v-model="form.phone" style="width: 210px" placeholder="请输入手机号"> </el-input>
                        </el-form-item>
                        <div @keyup.enter="loginPhone">
                            <el-form-item prop="code" :rules="{ required: true, message: '请输入', trigger: 'blur' }">
                                <span class="icon">
                                    <img
                                        src="../assets/login_icon_yanzhengma@3x (1).png"
                                        alt=""
                                        class="verification_code_img"
                                    />
                                </span>
                                <div>
                                    <el-input
                                        style="width: 310px"
                                        v-model="form.code"
                                        maxlength="4"
                                        placeholder="请输入短信验证码"
                                    >
                                        <el-link
                                            @click="send"
                                            :class="time === 0 ? 'code' : 'code2'"
                                            type="primary"
                                            :underline="false"
                                            slot="suffix"
                                            >{{ time === 0 ? '发送验证码' : `重新发送${time}s` }}</el-link
                                        >
                                    </el-input>
                                </div>
                            </el-form-item>
                        </div>
                    </el-form>
                    <div class="forgot_password" @click="forgotPassword">忘记密码？</div>
                    <div class="btn">
                        <div class="btn_one" @click="Login" v-if="active === 0">登录</div>
                        <div class="btn_one" @click="loginPhone" v-else>登录</div>
                        <div class="btn_two" @click="registeredAccount">注册账号</div>
                    </div>
                </div>
                <img
                    src="../assets/icon／close@3x (1).png"
                    alt=""
                    @click="$router.replace('/')"
                    class="container_login_con_return"
                />
            </div>
            <el-image
                fit="cover"
                src="https://baixiaip.oss-cn-hangzhou.aliyuncs.com/image/2022-11-14-14-08-53xGBeEcaO.png"
                class="container_login_img"
            />
        </div>
    </div>
</template>

<script>
// import EventBus from '../eventBus';
export default {
    data() {
        return {
            login: false,
            active: 0,
            show: false,
            phoneLogin: true,
            form: {
                region: '0'
            },
            time: 0,
            rules: {},
            list: [
                {
                    id: '0',
                    name: 'CH +86'
                }
            ]
        };
    },
    mounted() {
        // this.active = this.$route.query.type
    },
    components: {},
    methods: {
        forgotPassword() {
            this.$router.push({
                path: '/register',
                query: {
                    type: 2
                }
            });
        },
        registeredAccount() {
            this.$router.push({
                path: '/register',
                query: {
                    type: 1
                }
            });
        },
        loginPhone() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    this.$http
                        .get('/sms/verify', {
                            phone: this.form.phone,
                            code: this.form.code
                        })
                        .then(res => {
                            localStorage.setItem('webToken', res);
                            this.$http
                                .post('/auth/phoneLogin', {
                                    phone: this.form.phone,
                                    code: this.form.code
                                })
                                .then(res => {
                                    localStorage.setItem('webToken', res);
                                    this.$message.warning('登录成功');
                                    this.$store.dispatch('getUserInfo');
                                    this.$router.replace('/');
                                })
                                .catch(e => {
                                    this.loading = false;
                                    if (e) {
                                        this.$message.error(e.error);
                                    }
                                });
                        })
                        .catch(e => {
                            this.$message.error(e.error);
                        });
                }
            });
        },
        Login() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    this.$http
                        .post('/auth/login', {
                            username: this.form.username,
                            password: this.form.password
                        })
                        .then(res => {
                            localStorage.setItem('webToken', res);
                            this.$message.warning('登录成功');
                            // this.$store.commit('loginTypeFn', true);
                            return this.$store.dispatch('getUserInfo');
                        })
                        .then(() => {
                            this.$router.replace('/');
                        })
                        .catch(e => {
                            this.loading = false;
                            this.$message.error(e.error);
                        });
                }
            });
        },
        send() {
            this.sendCode(this.form.phone);
        },
        password() {
            this.$router.push({
                path: '/Register',
                query: {
                    identification: 1
                }
            });
        },
        Register() {
            this.$router.push({
                path: '/Register',
                query: {
                    identification: 2
                }
            });
        }
    }
};
</script>

<style lang="less" scoped>
.container {
    width: 100%;
    height: 100vh;
    background-image: url('../assets/png-denglu-bg@3x.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 0 260px;
    box-sizing: border-box;
    overflow-y: auto;
    display: flex;
    .container_login {
        align-items: center;
        width: 100%;
        margin: 0 auto;
        // margin-top: 20vh !important;
        display: flex;
        position: relative;
        .container_login_con {
            align-items: center;
            position: absolute;
            left: 0;
            // top: 0;
            width: 450px;
            height: 600px;
            background: rgba(255, 255, 255, 0.8);
            border-radius: 8px 0px 0px 8px;
            backdrop-filter: blur(20px);
            z-index: 1;
            .container_login_con_img {
                margin-left: 26px;
                margin-top: 30px;
                width: 150px;
                height: 50px;
                margin-bottom: 35px;
            }
            .container_login_con_tips {
                padding: 0px 79px;
                display: flex;
                justify-content: space-between;
                margin-bottom: 40px;
                .container_login_con_tips_con {
                    font-size: 24px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: bold;
                    color: #a4acb6;
                    line-height: 34px;
                    cursor: pointer;
                }
                .container_login_con_tips_con_active {
                    color: #000000;
                    position: relative;
                    &::after {
                        content: '';
                        width: 48px;
                        height: 2px;
                        background: #000000;
                        border-radius: 2px;
                        position: absolute;
                        left: 24px;
                        bottom: -2px;
                    }
                }
            }
        }
        .container_login_con_return {
            margin-left: 206px;
            margin-top: 60px;
            width: 38px;
            height: 38px;
        }
        .container_login_img {
            width: 100%;
            height: 600px;
        }
    }
}
/deep/ .el-dialog {
    margin-top: 25vh !important;
    position: relative;
}
.tips {
    position: absolute;
    top: 30px;
    left: 134px;
    display: flex;
    font-weight: 500;
    .tips_con {
        color: #bcc1cc;
        font-size: 24px !important;
        line-height: 34px;
        cursor: pointer;
    }
    .tips_con:nth-of-type(odd) {
        margin-right: 100px;
    }
    .active {
        color: #292c33;
        line-height: 34px;
        position: relative;
        &::after {
            content: '';
            width: 48px;
            height: 2px;
            background: #000000;
            border-radius: 2px;
            position: absolute;
            left: 24px;
            bottom: -2px;
        }
    }
}
/deep/ .el-form {
    margin-left: 60px !important;
    margin-right: 60px !important;
    margin-top: 0px !important;
}
/deep/ .el-form-item__content {
    display: flex;
    border-bottom: solid 1px #dddee0;
}
/deep/ .el-input__inner {
    border: none;
    background: rgba(255, 255, 255, 0) !important;
}
.el-icon-user {
    font-size: 24px;
    margin-top: 8px;
}
.el-icon-unlock {
    font-size: 24px;
    margin-top: 8px;
}
.forgot_password {
    font-size: 12px;
    font-weight: 400;
    color: #01a041;
    line-height: 17px;
    margin-left: 330px;
    margin-top: 10px;
    cursor: pointer;
}
.btn {
    margin-top: 40px;
    margin-left: 60px;
    cursor: pointer;
    .btn_one {
        width: 330px;
        height: 40px;
        background: #01a041;
        border-radius: 4px;
        font-size: 12px;
        font-weight: bold;
        color: #ffffff;
        line-height: 40px;
        margin-bottom: 20px;
        text-align: center;
    }
    .btn_two {
        width: 330px;
        height: 40px;
        background: #ffffff;
        border-radius: 4px;
        font-size: 12px;
        font-weight: bold;
        color: #939599;
        line-height: 40px;
        text-align: center;
    }
}
.verification_code_img {
    width: 24px;
    height: 24px;
    margin-top: 8px;
}
/deep/ .el-link--primary {
    color: #ffffff !important;
}
.code {
    width: 100px;
    height: 34px;
    background: #c8c9cc;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 400;
    color: #ffffff;
    line-height: 34px;
    cursor: pointer;
}
.code2 {
    width: 100px;
    height: 34px;
    background: #01a041;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 400;
    color: #ffffff;
    line-height: 34px;
    cursor: pointer;
}
</style>
